import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal, Textarea } from 'components';
import { TicketService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage2 } from 'assets/img';
import moment from 'moment';

interface Props {
	onClose?: any;
}

class ModalEditPrice extends React.Component<Props> {

	state = {
		form: {
			price: ''
		},
		actual_price: ''
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await TicketService.admin.getPrice();
		this.setState({
			form: {
				...this.state.form,
				price: res.price?.price || ''
			},
			actual_price: res.price?.price || ''
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		await TicketService.admin.updatePrice({
			price: this.state.form.price
		});
		this.props.onClose();
		Globals.showSuccess("Se ha modificado el precio de los tickets");
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="modal-edit-price-tickets">
				<div className="current-price">
					<p>Costo Actual del Ticket</p>
					<p className="price">{ Globals.formatMiles(this.state.actual_price) }</p>
				</div>
				<form onSubmit={ this.submit }>
					<Input
						type="decimal"
						color="white"
						value={ this.state.form.price }
						name="price" 
						label="Costo nuevo"
						placeholder="$0.00"
						onChange={ this.change } />
					<div className="text-center">
						<Button type="submit">
							Guardar
						</Button>
					</div>
				</form>
			</div>
		)
	}
}

export default ModalEditPrice;