import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal, Textarea } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents, Colors } from 'utils';
import moment from 'moment';
import { OrderService } from 'services';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalView extends React.Component<Props> {

	state = {
		order: null
	}

	componentDidMount() {
		this.load();
	}
	
	load = async () => {
		const res: any = await OrderService.admin.view({
			order_id: this.props.item?.id
		});
		this.setState({
			order: res.order
		});
	}

	changeStatus = async (e: any, status: number) => {
		e.preventDefault();
		let message = '';

		switch(status) {
			case Constants.ORDERS_STATUS.APPROVED:
				message = '¿Desea APROBAR el pedido?';
			break;

			case Constants.ORDERS_STATUS.FINISH:
				message = '¿Desea FINALIZAR el pedido?';
			break;

			case Constants.ORDERS_STATUS.REJECTED:
				message = '¿Desea RECHAZAR el pedido?';
			break;
		}

		Globals.confirm(message,async () => {
			await OrderService.admin.changeStatus({
				order_id: this.props.item?.id,
				status
			});
			Globals.showSuccess("Se ha cambiado el estatus del pedido correctamente");
			this.props.onClose();
		});
	}

	getColor = () => {
		let color = '';

		switch(this.props.item?.status) {
			case Constants.ORDERS_STATUS.PENDING:
				color = Colors.yellow;
			break;

			case Constants.ORDERS_STATUS.APPROVED:
				color = Colors.blue;
			break;

			case Constants.ORDERS_STATUS.FINISH:
				color = Colors.green;
			break;

			case Constants.ORDERS_STATUS.REJECTED:
				color = Colors.danger;
			break;
		}

		return color;
	}

	render() {
		const order: any = this.state.order;
		const item: any = order?.payment_reference;

		return (
			<div id="modal-view-order">
				{
					order != null && (
						<>
							<div className="row">
								<div className="col-md-6">
									<h4>Pedido Nº { order?.number }</h4>
									<p><strong>Usuario:</strong> { order?.user?.person != null ? (order?.user?.person?.name + ' ' + order?.user?.person?.lastname) : '' }</p>
									<p><strong>Subotal:</strong> { Globals.formatMiles(order?.subtotal) }</p>
									<p><strong>IVA ({ Globals.formatMiles(order?.iva_percentage,true,'') }%):</strong> { Globals.formatMiles(order?.iva) }</p>
									<p><strong>Total:</strong> { Globals.formatMiles(order?.total) }</p>
									<p><strong>Estatus:</strong> <span className="status" style={{ color: this.getColor() }}>{ order?.status_text }</span></p>
								</div>
								<div className="col-md-6">
									<h4>Datos de Pago</h4>

									{
										order?.pay_with_tickets == Constants.PAY_WITH_TICKETS.YES ? (
											<>
												<p className="message-tickets">El pedido fue pagado con Tickets</p>
											</>
										) : (
											<>
												{ item?.created_at && <p><strong>Fecha:</strong> { moment(item.created_at).format('DD/MM/YYYY') }</p> }
												{ item?.payment_method && <p><strong>Método de Pago:</strong> { item?.payment_method?.name }</p> }
												{ item?.phone && <p><strong>Teléfono:</strong> { item?.phone }</p> }
												{ item?.document && <p><strong>Documento de Identidad:</strong> { item?.document }</p> }
												{ item?.bank && <p><strong>Banco:</strong> { item?.bank?.name }</p> }
												{ item?.name_zelle && <p><strong>Nombre (Zelle):</strong> { item?.name_zelle }</p> }
												{ item?.lastname_zelle && <p><strong>Apellido (Zelle):</strong> { item?.lastname_zelle }</p> }
												{ item?.reference && <p><strong>Referencia:</strong> { item?.reference }</p> }
												{ item?.quantity_cash && <p><strong>Efectivo:</strong> { Globals.formatMiles(item?.quantity_cash) }</p> }
											</>
										)
									}
								</div>
							</div>	

							<div className="container-table">
								<table className="table table-bordered">
									<tr>
										<th style={{ width: '25%' }}>Producto</th>
										<th style={{ width: '15%' }}>Tamaño</th>
										<th style={{ width: '15%' }}>Color</th>
										<th style={{ width: '15%' }}>Cantidad</th>
										<th style={{ width: '15%' }}>Precio</th>
										<th style={{ width: '15%' }}>Total</th>
									</tr>
									{
										order?.details.map((item: any) => {
											return (
												<tr>
													<td style={{ width: '25%' }}>{ item?.product?.name || '-' }</td>
													<td style={{ width: '15%' }}>{ item?.size_color?.size?.name || '-' }</td>
													<td style={{ width: '15%' }}>{ item?.size_color?.color?.name || '-' }</td>
													<td style={{ width: '15%' }}>{ item?.quantity || '-' }</td>
													<td style={{ width: '15%' }}>{ Globals.formatMiles(item?.price) }</td>
													<td style={{ width: '15%' }}>{ Globals.formatMiles(item?.total) }</td>
												</tr>
											)
										})
									}
								</table>
							</div>					

							{
								order?.status == Constants.ORDERS_STATUS.PENDING && (
									<div className="row row-buttons">
										<div className="col-md-6 text-center">
											<button className="btn btn-accept" onClick={ (e: any) => this.changeStatus(e,Constants.ORDERS_STATUS.APPROVED) }>
												Aprobar
											</button>
										</div>
										<div className="col-md-6 text-center">
											<button className="btn btn-reject" onClick={ (e: any) => this.changeStatus(e,Constants.ORDERS_STATUS.REJECTED) }>
												Rechazar
											</button>
										</div>
									</div>
								)
							}
							{
								order?.status == Constants.ORDERS_STATUS.APPROVED && (
									<div className="text-center row-buttons">
										<button className="btn btn-finish" onClick={ (e: any) => this.changeStatus(e,Constants.ORDERS_STATUS.FINISH) }>
											Finalizar
										</button>
									</div>
								)
							}
						</>
					)
				}
			</div>
		)
	}
}

export default ModalView;