import React from 'react';
import { Table, Pagination, DatePicker } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage2 } from 'assets/img';
import { CleanIcon } from 'assets/icons';
import moment from 'moment';
import { TicketService } from 'services';

interface Props {
	onClose?: any;
	item?: any;
}

const INITIAL_STATE = {
	search: '',
	since: '',
	until: ''
}

class ModalViewTickets extends React.Component<Props> {

	state = {
		form: INITIAL_STATE,
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Tipo de Movimiento',
			'Fecha',
			'Movimiento',
			'Tickets Disponibles'
		]
	}

	componentDidMount() {
		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await TicketService.admin.viewTickets({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			user_id: this.props.item?.id,
			since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
			until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
		});
		this.setState({
			data: res.tickets.rows,
			last_page: res.tickets.count
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}
	
	render() {
		return (
			<div id="modal-view-tickets">
				<div className="row row-filter">
					<div className="col-md-4">
						<DatePicker
							label="Desde"
							placeholder="Desde"
							onChange={ (text: string) => {
								this.change({
									target: {
										value: text,
										name: 'since'
									}
								},() => this.load(true));
							} }
							value={ this.state.form.since }
						/>
					</div>
					<div className="col-md-4">
						<DatePicker
							label="Hasta"
							placeholder="Hasta"
							onChange={ (text: string) => {
								this.change({
									target: {
										value: text,
										name: 'until'
									}
								},() => this.load(true));
							} }
							value={ this.state.form.until }
						/>
					</div>
					<div className="col-md-4">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.type?.name }</td>
								<td>{ i.created_at ? moment(i.created_at).format('DD/MM/YYYY') : '' }</td>
								<td>{ i.movement_amount }</td>
								<td>{ i.final_amount }</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default ModalViewTickets;