import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { CleanIcon, PasswordActive } from 'assets/icons';
import { Globals, Constants, Colors, checkPermission, Permissions } from 'utils';
import { TicketService } from 'services';
import { WhiteImage } from 'assets/img';
import { Table, Pagination, Input, DatePicker, Button, Modal, Tooltip } from 'components';
import ModalEditPrice from './modal-edit-price';
import ModalViewTickets from './modal-view-tickets';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	since: '',
	until: ''
}

class AdminTickets extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Nombre del usuario',
			'Tickets comprados',
			'Fecha compra',
			'Tickets canjeados',
			'Fecha Canje',
			'Tickets Disponibles',
			'Acciones'
		],
		form: INITIAL_STATE,
		visible: false,
		visible_view: false,
		item: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Tickets',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await TicketService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
			until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
		});
		this.setState({
			data: res.users.rows,
			last_page: res.users.count
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	editPrice = () => {
		this.setState({
			visible: true
		});
	}

	onClose = () => {
		this.setState({
			visible: false
		});
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_view: true,
			item
		});
	}

	onCloseView = () => {
		this.setState({
			visible_view: false,
			item: null
		});
	}
	
	render() {
		const { visible, visible_view } = this.state;

		return (
			<div id="admin-tickets">
				<Modal
		          className="modal-edit-price-tickets"
		          visible={ visible }
		          title="Editar costo"
		          onClose={ () => this.onClose() }
		        >
		          <ModalEditPrice
		            onClose={ () => this.onClose() } />
		      	</Modal>

		      	<Modal
		          className="modal-view-tickets"
		          visible={ visible_view }
		          title="Ver tickets"
		          onClose={ () => this.onCloseView() }
		        >
		          <ModalViewTickets
		          	item={ this.state.item }
		            onClose={ () => this.onCloseView() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-3">
						<Input
							value={ this.state.form.search }
							name="search"
							label="Nombre del Usuario"
							placeholder="Buscar"
							className="input-table"
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-3">
						<DatePicker
							label="Desde"
							placeholder="Desde"
							onChange={ (text: string) => {
								this.change({
									target: {
										value: text,
										name: 'since'
									}
								},() => this.load(true));
							} }
							value={ this.state.form.since }
						/>
					</div>
					<div className="col-md-3">
						<DatePicker
							label="Hasta"
							placeholder="Hasta"
							onChange={ (text: string) => {
								this.change({
									target: {
										value: text,
										name: 'until'
									}
								},() => this.load(true));
							} }
							value={ this.state.form.until }
						/>
					</div>
					<div className="col-md-3">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Historial de Compra y Canje de Tickets" data={ this.state.data.length } rightAlign={[ 6 ]} header={ this.state.header } titleRight={
					<React.Fragment>
						{
							checkPermission(Constants.MODULES.TICKETS,'write',this.props.user) && (
								<Button className="btn-add" onClick={ () => this.editPrice() }>
									Editar costo tickets
								</Button>
							)
						}
					</React.Fragment>
				}>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.fullName }</td>
								<td>{ i.tickets_purchased }</td>
								<td>{ i.last_purchase_date ? moment(i.last_purchase_date).format('DD/MM/YYYY') : '-' }</td>
								<td>{ i.tickets_used }</td>
								<td>{ i.last_used_date ? moment(i.last_used_date).format('DD/MM/YYYY') : '-' }</td>
								<td>{ i.current_tickets }</td>
								<td className="vertical-center text-right">
									<Tooltip title="Ver">
										<a className="link-icon link-view" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ PasswordActive } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(AdminTickets);