import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal, Textarea } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents, Colors } from 'utils';
import moment from 'moment';
import { ReportService } from 'services';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalViewPayment extends React.Component<Props> {

	changeStatus = (status: number) => {
		Globals.confirm(`¿Desea ${ status == Constants.PAYMENT_REFERENCES.APPROVED ? 'aprobar' : 'rechazar' } este pago?`,async () => {
			await ReportService.admin.changeStatusPayment({
				payment_id: this.props.item?.id,
				status
			});
			this.props.onClose();
		});
	}
	
	render() {
		const item = this.props.item;

		return (
			<div id="modal-view-payment">
				{ item?.created_at && <p><strong>Fecha:</strong> { moment(item.created_at).format('DD/MM/YYYY') }</p> }
				{ item?.user && <p><strong>Usuario:</strong> { item?.user?.person?.name } { item?.user?.person?.lastname }</p> }
				{ item?.pet && <p><strong>Mascota:</strong> { item?.pet?.name }</p> }
				{ item?.payment_method && <p><strong>Método de Pago:</strong> { item?.payment_method?.name }</p> }
				{ item?.phone && <p><strong>Teléfono:</strong> { item?.phone }</p> }
				{ item?.document && <p><strong>Documento de Identidad:</strong> { item?.document }</p> }
				{ item?.bank && <p><strong>Banco:</strong> { item?.bank?.name }</p> }
				{ item?.name_zelle && <p><strong>Nombre (Zelle):</strong> { item?.name_zelle }</p> }
				{ item?.lastname_zelle && <p><strong>Apellido (Zelle):</strong> { item?.lastname_zelle }</p> }
				{ item?.reference && <p><strong>Referencia:</strong> { item?.reference }</p> }
				{ item?.ticket_amount && <p><strong>Tickets:</strong> { item.ticket_amount }</p> }
				{ item?.amount && <p><strong>Monto:</strong> { Globals.formatMiles(item?.amount) }</p> }
				{ item?.quantity_cash && <p><strong>Efectivo:</strong> { Globals.formatMiles(item?.quantity_cash) }</p> }
				{ item?.reason_text && <p><strong>Motivo:</strong> { item.reason_text }</p> }
				{ item?.status_text && <p><strong>Estatus:</strong> { item.status_text }</p> }
				{
		      		item?.status == Constants.PAYMENT_REFERENCES.PENDING && (
		      			<div className="row row-buttons">
				      		<div className="col-md-6">
				      			<Button className="btn-reject" type="button" onClick={ () => this.changeStatus(Constants.PAYMENT_REFERENCES.REJECTED) }>
									Rechazar
								</Button>
				      		</div>
				      		<div className="col-md-6">
				      			<Button className="btn-accept" type="button" onClick={ () => this.changeStatus(Constants.PAYMENT_REFERENCES.APPROVED) }>
									Aprobar
								</Button>
				      		</div>
				      	</div>
		      		)
		      	}
			</div>
		)
	}
}

export default ModalViewPayment;