import React, { useEffect } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import {
  HashRouter as Router,
  Switch,
  Redirect,
  useHistory 
} from "react-router-dom";
import "moment/locale/es";
import { Route } from "components";
import { RootState } from "reducers";
import { Socket, SocketEvents, Constants, Globals } from 'utils';
import { AuthService } from 'services';

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// Public
import Home from 'screens/home';

// Auth
import Login from "screens/auth/login/login";
import Recover from "screens/auth/login/recover";
import Reset from "screens/auth/login/reset";
import Profile from "screens/profile";

// Admin Home
import Admin from 'screens/admin/home';
import AdminHome from 'screens/admin/home/home';
import AdminUsers from 'screens/admin/users/users';
import AdminClub from 'screens/admin/club/club';
import AdminFinancing from 'screens/admin/financing/financing';
import AdminSurgeries from 'screens/admin/financing/surgeries';
import AdminServices from 'screens/admin/services/services';
import AdminPlans from 'screens/admin/plans/plans';
import AdminTickets from 'screens/admin/tickets/tickets';
import AdminConversion from 'screens/admin/conversion/conversion';
import AdminReports from 'screens/admin/reports/reports';
import AdminHealthCenters from 'screens/admin/health-centers/health-centers';
import AdminServiceManagement from 'screens/admin/service-management/service-management';
import AdminPaymentManagement from 'screens/admin/payment-management/payment-management';
import AdminReportServices from 'screens/admin/reports/services';
import AdminReportClub from 'screens/admin/reports/club';
import AdminReportFinancing from 'screens/admin/reports/financing';
import AdminReportCodes from 'screens/admin/reports/generate-codes';
import AdminDocumentVerification from 'screens/admin/financing/document-verifications';
import AdminReportPets from 'screens/admin/reports/pets';
import AdminReportPlans from 'screens/admin/reports/plans';
import AdminReportPayments from 'screens/admin/reports/payments';
import AdminCards from 'screens/admin/cards/cards';
import AdminMicrochip from 'screens/admin/microchip/microchip';
import AdminProducts from 'screens/admin/products/products';
import AdminOrders from 'screens/admin/orders/orders';

// Practicing
import Practicing from 'screens/practicing/home';
import PracticingHome from 'screens/practicing/home/home';
import PracticingServices from 'screens/practicing/services/services';
import PracticingPayments from 'screens/practicing/payments/payments';

// Seller
import Seller from 'screens/seller/home';
import SellerReportCodes from 'screens/seller/generate-codes/generate-codes';

// Scripts
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./scss/main.scss";

const Container = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const getUser = async () => {
      const user = store.getState().user;
      if (user) {
        const res: any = await AuthService.checkUser({
          user_id: user?.id,
          withoutLoading: true
        });
        if (res.user) {
          dispatch({
            type: 'SET_USER',
            payload: res.user
          });
        }
        else {
          Globals.showError("Lo sentimos, su usuario ha sido eliminado");
          close();
        }
      }
    }
    getUser();

    const close = async () => {
      await dispatch({
        type: 'SET_USER',
        payload: null
      }); 
      history.replace('/logout');
    }

    Socket.on(SocketEvents.UPDATE_USER,(data: any) => {
      const user = store.getState().user;
      if (user != null && user?.id == data.user_id) {
        getUser();
      }
    });
  },[]);

  return (
    <React.Fragment>
      <Switch>
        {/* Public */}
        <Route exact path="/" component={ Home } />

        {/* Auth */}
        <Route exact noAuth path="/login" component={ Login } />
        <Route exact auth path="/profile" component={ Profile } />
        <Route exact noAuth path="/recover" component={ Recover } />
        <Route exact noAuth path="/recover/:code" component={ Reset } />

        {/* Admin */}

        {/* Home */}
        <Route exact admin path="/admin" component={ Admin } />  
        <Route exact admin path="/admin/home" component={ AdminHome } permission={ Constants.MODULES.HOME } />
        <Route exact admin path="/admin/services" component={ AdminServices } permission={ Constants.MODULES.SERVICES } />
        <Route exact admin path="/admin/users" component={ AdminUsers } permission={ Constants.MODULES.USERS } />
        <Route exact admin path="/admin/club" component={ AdminClub } permission={ Constants.MODULES.CLUB } />
        <Route exact admin path="/admin/plans" component={ AdminPlans } permission={ Constants.MODULES.PLANS } />
        <Route exact admin path="/admin/tickets" component={ AdminTickets } permission={ Constants.MODULES.TICKETS } />
        <Route exact admin path="/admin/conversion" component={ AdminConversion } permission={ Constants.MODULES.CONVERSION } />
        <Route exact admin path="/admin/financing" component={ AdminFinancing } permission={ Constants.MODULES.FINANCING } />
        <Route exact admin path="/admin/financing/surgeries" component={ AdminSurgeries } permission={ Constants.MODULES.FINANCING } />
        <Route exact admin path="/admin/financing/document-verifications" component={ AdminDocumentVerification } permission={ Constants.MODULES.FINANCING } />
        <Route exact admin path="/admin/codes" component={ AdminReportCodes } permission={ Constants.MODULES.CARDS } />
        <Route exact admin path="/admin/reports" component={ AdminReports } permission={ Constants.MODULES.REPORTS } />
        <Route exact admin path="/admin/reports/plans" component={ AdminReportPlans } permission={ Constants.MODULES.REPORTS }  />
        <Route exact admin path="/admin/reports/pets" component={ AdminReportPets } permission={ Constants.MODULES.REPORTS } />
        <Route exact admin path="/admin/reports/services" component={ AdminReportServices } permission={ Constants.MODULES.REPORTS } />
        <Route exact admin path="/admin/reports/club" component={ AdminReportClub } permission={ Constants.MODULES.REPORTS } />
        <Route exact admin path="/admin/reports/financing" component={ AdminReportFinancing } permission={ Constants.MODULES.REPORTS } />
        <Route exact admin path="/admin/reports/payments" component={ AdminReportPayments } permission={ Constants.MODULES.REPORTS } />
        <Route exact admin path="/admin/health-centers" component={ AdminHealthCenters } permission={ Constants.MODULES.HEALTH_CENTERS } />
        <Route exact admin path="/admin/service-management" component={ AdminServiceManagement } permission={ Constants.MODULES.SERVICE_MANAGEMENT } />
        <Route exact admin path="/admin/payment-management" component={ AdminPaymentManagement } permission={ Constants.MODULES.PAYMENT_MANAGEMENT } />
        <Route exact admin path="/admin/cards" component={ AdminCards } permission={ Constants.MODULES.CARDS } />
        <Route exact admin path="/admin/microchip" component={ AdminMicrochip } permission={ Constants.MODULES.MICROCHIP } />
        <Route exact admin path="/admin/products" component={ AdminProducts } permission={ Constants.MODULES.PRODUCTS } />
        <Route exact admin path="/admin/orders" component={ AdminOrders } permission={ Constants.MODULES.ORDERS } />

        {/* Practicing */}

        {/* Home */}
        <Route exact practicing path="/practicing" component={ Practicing } />  
        <Route exact practicing path="/practicing/home" component={ PracticingHome } />
        <Route exact practicing path="/practicing/services" component={ PracticingServices } />
        <Route exact practicing path="/practicing/payments" component={ PracticingPayments } />

        {/* Seller */}
        <Route exact seller path="/seller" component={ Seller } />  
        <Route exact seller path="/seller/codes" component={ SellerReportCodes } />

        <Redirect to="/" />
      </Switch>
    </React.Fragment>
  )
}

const App = () => (
  <Provider store={ store }>
    <PersistGate loading={ null } persistor={ persistor }>
      <Router>
        <Container />
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
